import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Avatar,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PracticeChip from '../PracticeChip'
import { StoreContext } from '../../StoreContext'
import styles from '../../css-styles'
import AnswerButton from './AnswerButtonDG'
import Timer from '../Timer'
import TestInstructions from '../TestInstructions'
import useQuestionStartTime from '../../hooks/useQuestionStartTime'
import Loading from '../Loading'

const useStyles = makeStyles({
  digitSignImage: styles.digitSignImage,
  digitSignImageTarget: styles.digitSignImageTarget,
  justifySpaceEvenly: styles.justifySpaceEvenly,
  marginXAuto: styles.marginXAuto,
  avatarLarge: styles.avatarLarge,
  digitSignTimerPrimary: styles.digitSignTimerPrimary,
  digitSignTimerSecondary: styles.digitSignTimerSecondary,
  digitSignNumberOutline: styles.digitSignNumberOutline,
})

const DigitSign = ({ q, p }) => {
  const store = useContext(StoreContext)
  const classes = useStyles()
  const [responseItems, setResponseItems] = useState(null)
  const [questionItem, setQuestionItems] = useState(null)
  const [gridDisplay, setGridDisplay] = useState(null)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [preloadList, setPreloadList] = useState([])
  const { getTimer, percentTimeSpent, displayEndScreen } =
    useContext(StoreContext)
  const [startingPercent, setStartingPercent] = useState(0)
  const [timeExpired, setTimeExpired] = useState(false)
  const [sleepCounter, setSleepCounter] = useState(0)

  useQuestionStartTime()
  // Updated with every timer update.
  const onTimeUsed = (percent) => {
    setStartingPercent(percent)
  }
  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements
  if (preloadList.length === 0) {
    const dummyImages = []
    const imageUrls = q.sorted_image_urls
    imageUrls.forEach((imageUrl) => {
      const dummyImage = new Image()
      dummyImage.src = imageUrl
      dummyImages.push(dummyImage)
    })
    setPreloadList(dummyImages)
  }

  useEffect(() => {
    // used to increment a counter each second to rerun the effect hook to check for image load completion
    async function incrementSleep(duration) {
      await new Promise((r) => setTimeout(r, duration))
      setSleepCounter(sleepCounter + 1)
    }
    const allImagesLoaded = preloadList.reduce(
      (previousValue, currentValue) => {
        return previousValue && currentValue.complete
      },
      true
    )
    if (allImagesLoaded) {
      setImageLoaded(allImagesLoaded)
    } else {
      setImageLoaded(false)
      const sleepDuration = sleepCounter >= 10 ? 1000 : 100
      incrementSleep(sleepDuration)
    }
  }, [imageLoaded, preloadList, setImageLoaded, sleepCounter])

  useLayoutEffect(() => {
    const order = []
    const questionTextOrder = []
    const gridOrder = []
    const sortedImageUrls = q.sorted_image_urls
    const avatarClass = p.use_legacy_ui ? '' : classes.digitSignNumberOutline

    sortedImageUrls.forEach((imageUrl, index) => {
      gridOrder.push(
        <Grid item xs={4} key={`grid-item-${imageUrl}`}>
          <CardMedia className={classes.digitSignImage} image={imageUrl} />
          <Avatar className={`${classes.marginXAuto} ${avatarClass}`}>
            {index + 1}
          </Avatar>
        </Grid>
      )
    })
    questionTextOrder.push(
      <>
        <Typography>{store.getString('isCorrectPair')}</Typography>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <CardMedia
              className={classes.digitSignImageTarget}
              image={q.target_image}
              title={q.name}
            />
          </Grid>
          <Grid item xs={6}>
            <Avatar
              className={`${classes.marginXAuto} ${classes.avatarLarge} ${avatarClass}`}
            >
              {q.display_number}
            </Avatar>
          </Grid>
        </Grid>
      </>
    )
    order.push(
      <CardActions className={classes.justifySpaceEvenly}>
        <AnswerButton
          answer={{ text: store.getString('yes'), isCorrect: q.is_correct }}
          percentTimeUsed={startingPercent}
        />
        <AnswerButton
          answer={{ text: store.getString('no'), isCorrect: !q.is_correct }}
          percentTimeUsed={startingPercent}
        />
      </CardActions>
    )

    setResponseItems(order)
    setQuestionItems(questionTextOrder)
    setGridDisplay(gridOrder)
  }, [
    setResponseItems,
    setGridDisplay,
    q,
    p,
    classes.digitSignImage,
    classes.justifySpaceEvenly,
    classes.marginXAuto,
    classes.avatarLarge,
    classes.digitSignImageTarget,
    classes.digitSignNumberOutline,
    percentTimeSpent,
    startingPercent,
    store,
    timeExpired,
    displayEndScreen,
  ])

  if (!imageLoaded) {
    return <Loading />
  }

  if (timeExpired === false && displayEndScreen === false) {
    return (
      <Card className={classes.width95}>
        {store.isPractice && (
          <CardContent>
            <PracticeChip />
          </CardContent>
        )}
        <CardContent>
          <Grid container spacing={1}>
            {gridDisplay}
          </Grid>
        </CardContent>
        <CardContent>{questionItem}</CardContent>
        {responseItems}
        <Timer
          classes={{
            colorPrimary: classes.digitSignTimerPrimary,
            bar1Determinate: classes.digitSignTimerSecondary,
          }}
          duration={getTimer()}
          onTimeExpired={() => {
            setTimeExpired(true)
          }}
          onTimeUsed={onTimeUsed}
          percentInitializer={percentTimeSpent}
        />
      </Card>
    )
  }
  return (
    <TestInstructions
      key={store.location.phase}
      title={p.end_screen_title}
      body={p.end_screen_body}
      nextButtonLabelOverride
      onClickOverride={store.nextPhase}
    />
  )
}

export default DigitSign
