import React, { useContext, useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Grow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PracticeChip from '../PracticeChip'
import { StoreContext } from '../../StoreContext'
import AnswerButtonTrio from '../AnswerButtonTrio'
import styles from '../../css-styles'
import Loading from '../Loading'

const useStyles = makeStyles({
  width95: styles.width95,
  faceHero: styles.faceHero,
  threeJobs: styles.threeJobs,
})

const Recall = ({ q }) => {
  const classes = useStyles()
  const { isPractice, getString } = useContext(StoreContext)
  const [imageLoaded, setImageLoaded] = useState(true)
  const [sleepCounter, setSleepCounter] = useState(0)
  const [preloadedImage] = useState(new Image())
  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements
  if (preloadedImage.src === '') {
    preloadedImage.src = q.image
  }
  // Once dummyImage.complete is updated, this will fire and remove loading screen.
  useEffect(() => {
    // Every second increment a counter to check if the image is done loading.
    async function incrementSleep(duration) {
      await new Promise((r) => setTimeout(r, duration))
      setSleepCounter(sleepCounter + 1)
    }
    if (imageLoaded && preloadedImage.complete) {
      return
    }
    if (preloadedImage.complete) {
      setImageLoaded(preloadedImage.complete)
    } else {
      setImageLoaded(false)
      const sleepDuration = sleepCounter >= 10 ? 1000 : 100
      incrementSleep(sleepDuration)
    }
  }, [imageLoaded, setImageLoaded, preloadedImage.complete, sleepCounter])
  if (!imageLoaded) {
    return <Loading />
  }
  return (
    <Card className={classes.width95}>
      <CardContent>
        {isPractice && <PracticeChip />}
        <Typography>{getString('whatPrice')}</Typography>
        <Grow in>
          <CardMedia className={classes.faceHero} image={q.image} />
        </Grow>
      </CardContent>
      <Grow in>
        <CardActions className={classes.threeJobs} disableSpacing>
          <AnswerButtonTrio
            answer={{
              value: `$${q.recall_price_1}`,
              isCorrect: q.correct_recall_price === 1,
            }}
          />
          <AnswerButtonTrio
            answer={{
              value: `$${q.recall_price_2}`,
              isCorrect: q.correct_recall_price === 2,
            }}
          />
          <AnswerButtonTrio
            answer={{
              value: `$${q.recall_price_3}`,
              isCorrect: q.correct_recall_price === 3,
            }}
          />
        </CardActions>
      </Grow>
    </Card>
  )
}

export default Recall
