import React, { useContext } from 'react'
import { Card, CardContent, Grow, Typography } from '@material-ui/core'

import { StoreContext } from '../../StoreContext'

const DeniedConsent = () => {
  const store = useContext(StoreContext)
  const { getString } = store
  return (
    <Grow in>
      <Card>
        <CardContent>
          <Typography variant="h4">Consent Required</Typography>
          <Typography variant="body1">
            {getString('consentRequiredBody')}
          </Typography>
        </CardContent>
      </Card>
    </Grow>
  )
}

export default DeniedConsent
