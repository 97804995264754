const defaultData = {
  strings: {
    // English
    en: {
      areYouThere: 'Are you ready to proceed?',
      beginButtonLabel: 'Begin',
      nextButtonLabel: 'Next',
      requestId: 'Please enter your (Unique ID)',
      submitButtonLabel: 'Submit',
      loading: 'Loading',
      loadingMessage:
        'If this takes longer than 60 seconds, please try again later.',
      startTimerButtonLabel: 'Start Timer',
      audioScreenTitle: 'Audio',
      audioScreenCorrect: 'Some chime for a correct answer.',
      audioScreenIncorrect: 'Some sections beep for an incorrect answer.',
      audioScreenBody1:
        'Tap each ear above to familiarize yourself with the sounds.',
      audioScreenBody2: ' Please adjust your volume to a comfortable level.',
      fitsLabel: 'Fits',
      doesntFitLabel: `Doesn't Fit`,
      practiceChip: 'Practice',
      testChip: 'Test',
      assignment404: 'We were not able to find that assignment.',
      assignmentNotStarted: 'This assignment has not started yet.',
      assignmentConcluded: 'This assignment has concluded.',
      assignmentAppreciation: 'We appreciate your participation!',
      // assignmentWindowEnded: 'The window for this assignment has ended.',
      assignmentWelcome: 'Welcome to BRANCH!',
      start: 'Start',
      end: 'End',
      tapPair: 'Tap the pair you saw.',
      selectSimilar: 'Select the most similar to:',
      genericError: 'Something has gone wrong.',
      saving: 'Saving',
      doNotCloseTab: 'Please do not close this window.',
      timerExplanation: 'Timer Explanation',
      timerInstructions:
        'Many of the learning and memory items are timed.<br><br>The timer is a purple bar at the bottom of the screen.<br><br>Tap “Start Timer” to see the Timer.',
      explanationCorrect: `That's right!`,
      explanationRemember: 'Try to remember this pair.',
      groceriesPicMatchingPrompt: 'Is this the exact picture you saw?',
      inWaitingPeriod: `You are up to date for this test.`,
      pleaseReturn: 'Please return',
      yes: 'yes',
      no: 'no',
      wasCorrectPair: 'Was this the correct pair?',
      sawSign: 'Did you see this sign?',
      tryRememberPair: 'Try to remember this pair.',
      whatPrice: 'What was the price?',
      howConfident: 'How confident are you?',
      isCorrectPair: 'Is this the correct pair?',
      confident: 'Confident',
      notConfident: 'Not Confident',
      invalidLink: 'Invalid link. Please contact your administrator.',
      selectAllApply: 'Check all that apply',
      noneOfTheAbove: 'None of the Above',
      firstLetter: 'First Letter',
      digitSignRecallPrompt:
        'Please select the number that was paired with this sign',
      feedbackTextNormal: 'Overall, this is what your results looked like.',
      feedbackTextImproved: 'Overall, your performance improved.',
      testScores: 'Test Scores',
      testScore: 'Test Score',
      testDayAxis: 'Test Day',
      assignmentResults: 'Assignment Results',
      dayLabel: 'Day',
      email: 'Email Address',
      consentForm: 'Consent Form',
      userInfo: 'User Information',
      consentPositive: 'I consent',
      consentNegative: 'I do not consent',
      consentRequired: 'Consent Required',
      consentRequiredBody:
        'Thank you for your participation, unfortunately you must sign the consent form to continue with this study.',
      notApplicable: 'Not Applicable',
      failedLoadTest:
        'Failed to load test. Please contact a test administrator and reference error code:',
    },
    // Spanish
    es: {
      areYouThere: '¿Está listo para continuar?',
      beginButtonLabel: 'Comenzar',
      nextButtonLabel: 'Siguiente',
      requestId: 'Por favor, ingrese su código de identificación',
      submitButtonLabel: 'Enviar',
      loading: 'Cargando',
      loadingMessage:
        'Cargando, si esto se demora más de 60 segundos, intente de nuevo en unos minutos.',
      startTimerButtonLabel: 'Comenzar el cronómetro',
      audioScreenTitle: 'Audio',
      audioScreenCorrect:
        'Algunos ejercicios incluyen un sonido para indicar que su respuesta es correcta',
      audioScreenIncorrect:
        'En algunas secciones escuchará un sonido para indicar que su respuesta es incorrecta.',
      audioScreenBody1:
        'Presione cada símbolo para familiarizarse con los sonidos.',
      audioScreenBody2:
        'Por favor ajuste el nivel del volumen si es necesario.',
      fitsLabel: 'Encaja',
      doesntFitLabel: 'No Encaja',
      practiceChip: 'Práctica',
      testChip: 'Prueba',
      assignment404: 'No pudimos encontrar esa tarea.',
      assignmentNotStarted: 'Esta tarea aún no ha comenzado.',
      assignmentConcluded: 'Esta tarea ha terminado.',
      assignmentAppreciation: '¡Gracias!',
      // assignmentWindowEnded: 'Puede cerrar esta ventana.',
      assignmentWelcome:
        '¡Bienvenido! Boston Remote Assessment of Neurocognitive Health: Estudio Remoto de Boston para la Salud Neurocognitiva',
      start: 'Comenzar',
      end: 'Fin',
      tapPair: 'Elija el par que vio anteriormente.',
      selectSimilar: 'Seleccione la imagen más relacionada a:',
      genericError: 'Algo ha salido mal.',
      saving: 'Guardando,',
      doNotCloseTab: 'Guardando, por favor no cierre esta ventana.',
      timerExplanation: 'Explicación del cronómetro',
      timerInstructions:
        'Muchos de los ejercicios de aprendizaje y memoria utilizarán un cronómetro.<br><br>El reloj se encuentra en la barra morada en la parte de abajo de la pantalla.<br><br>Presione “Comenzar el cronómetro” para ver el reloj.',
      explanationCorrect: 'Correcto',
      explanationRemember: 'Intente recordar este par.',
      groceriesPicMatchingPrompt: '¿Es ésta la imagen exacta que vió?',
      inWaitingPeriod: 'Está al día con las pruebas.',
      pleaseReturn: 'Por favor regrese en',
      yes: 'SÍ',
      no: 'No',
      wasCorrectPair: '¿Era éste el par correcto?',
      sawSign: '¿Vio esta señal de tráfico anteriormente?',
      tryRememberPair: 'Intente recordar este par.',
      whatPrice: '¿Cuál era el precio?',
      howConfident: '¿Qué tan seguro está de su respuesta?',
      isCorrectPair: '¿Es éste el par correcto?',
      confident: 'Seguro',
      notConfident: 'No Seguro',
      invalidLink: 'Invalid link. Please contact your administrator.',
      selectAllApply: 'Marque todas las respuestas que correspondan',
      noneOfTheAbove: 'Ninguna de las anteriores',
      firstLetter: 'Primera Letra',
      digitSignRecallPrompt:
        'Por favor seleccione el número que correspondia con este signo',
      testScores: 'Resultados de las pruebas',
      feedbackTextNormal: 'En total, estos son sus resultados.',
      feedbackTextImproved: 'En total, su rendimiento mejoró.',
      testScore: 'Resultado de la prueba',
      testDayAxis: 'Día de la prueba',
      assignmentResults: 'Resultados de la evaluación',
      dayLabel: 'Día',
      email: 'Correo electrónico',
      consentForm: 'Formulario de consentimiento',
      userInfo: 'Informacion del usuario',
      consentPositive: 'Yo consiento',
      consentNegative: 'No consiento',
      consentRequired: 'Consentimiento requerido',
      consentRequiredBody:
        'Gracias por su participación, lamentablemente debe firmar el formulario de consentimiento para continuar con este estudio.',
      notApplicable: 'No aplica',
      failedLoadTest:
        'No se pudo cargar la prueba. Comuníquese con su administrador de pruebas y haga referencia al código de error:',
    },
  },
  phases: [
    {
      has_audio_feedback: false,
      has_visual_feedback: false,
      id: 0,
      index: 0,
      instructions_body: '',
      instructions_title: '',
      is_practice: false,
      is_timed: false,
      name: '',
      react_component: '',
      task: '',
      timer_seconds: null,
    },
  ],
}

export default defaultData
