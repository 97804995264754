import React, { useState, useContext } from 'react'
import { Card, CardContent, Grow, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ButtonNext from './ButtonNext'
import { StoreContext } from '../StoreContext'
import CircularDeterminate from './CircularDeterminate'
import PracticeChip from './PracticeChip'
import TestChip from './TestChip'
import styles from '../css-styles'
import { TIMER_INSTRUCTIONS, RC_INSTRUCTIONS } from '../constants'

const useStyles = makeStyles({
  alignSelfCenter: styles.alignSelfCenter,
  fixed: styles.fixed,
  circleSize: styles.circleSize,
})

const TestInstructions = ({
  title,
  body,
  nextButtonLabelOverride = false,
  onClickOverride = null,
}) => {
  const classes = useStyles()
  const [disabled, setDisabled] = useState(true)
  const store = useContext(StoreContext)
  const phase = store.getPhase(store.afterFeedback)
  const onClickHandler =
    onClickOverride !== null ? onClickOverride : store.nextQuestion
  const buttonLabel =
    phase.react_component === RC_INSTRUCTIONS || nextButtonLabelOverride
      ? store.getString('nextButtonLabel')
      : store.getString('beginButtonLabel')

  let badge = null

  if (store.showBadge) {
    badge = store.isPractice ? <PracticeChip /> : <TestChip />
  }

  return (
    <Grow in>
      <Card className={classes.fixed}>
        <CardContent>
          {badge}
          <Typography variant="h4">{title}</Typography>
        </CardContent>
        <CardContent className={classes.alignSelfCenter}>
          <Typography gutterBottom dangerouslySetInnerHTML={{ __html: body }} />
        </CardContent>
        <ButtonNext onClick={onClickHandler} disable={disabled}>
          {disabled ? (
            <CircularDeterminate
              callback={() => setDisabled(false)}
              timer={TIMER_INSTRUCTIONS}
              size={36}
            />
          ) : (
            buttonLabel
          )}
        </ButtonNext>
      </Card>
    </Grow>
  )
}

export default TestInstructions
