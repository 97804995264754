import React, { useContext } from 'react'
import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from '@material-ui/core/'
import { makeStyles } from '@material-ui/styles'
import { StoreContext } from '../StoreContext'
import styles from '../css-styles'

const useStyles = makeStyles({ icon: styles.icon })

const Loading = () => {
  const { getString } = useContext(StoreContext)
  const classes = useStyles()
  return (
    <Card>
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {getString('loading')}
        </Typography>
        <CircularProgress className={classes.icon} />
        <Typography variant="body1" gutterBottom>
          {getString('loadingMessage')}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default Loading
