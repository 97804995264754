import React, { useContext, useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Fab,
  Grow,
  Typography,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'
import PracticeChip from '../PracticeChip'
import Timer from '../Timer'
import { StoreContext } from '../../StoreContext'
import useQuestionStartTime from '../../hooks/useQuestionStartTime'
import styles from '../../css-styles'
import Loading from '../Loading'

const useStyles = makeStyles({
  button: styles.threeJobsAnswerButton,
  width95: styles.width95,
  face: {
    height: 'calc(var(--rvh, 1vh) * 42)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    borderRadius: '4px',
  },
  threeJobs: styles.threeJobs,
})

const QuestionFNameLearn = ({ q }) => {
  const classes = useStyles()
  const store = useContext(StoreContext)
  const { getString } = store
  useQuestionStartTime()

  const timer = store.getTimer()

  const onTimeExpired =
    store.attemptNumber !== 0 ? store.nextQuestion : store.askUserAreYouThere
  const [imageLoaded, setImageLoaded] = useState(true)
  const [sleepCounter, setSleepCounter] = useState(0)
  const [preloadImage] = useState(new Image())
  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements

  if (preloadImage.src === '') {
    preloadImage.src = q.target_image
  }

  // Once dummyImage.complete is updated, this will fire and remove loading screen.
  useEffect(() => {
    // Every second increment a counter to check if the image is done loading.
    async function incrementSleep(duration) {
      await new Promise((r) => setTimeout(r, duration))
      setSleepCounter(sleepCounter + 1)
    }
    if (imageLoaded === true && preloadImage.complete) {
      // The image is loaded we don't need to keep repeating this hook
      return
    }
    if (preloadImage.complete) {
      setImageLoaded(preloadImage.complete)
    } else {
      setImageLoaded(false)
      const sleepDuration = sleepCounter >= 10 ? 1000 : 100
      incrementSleep(sleepDuration)
    }
  }, [imageLoaded, setImageLoaded, preloadImage, sleepCounter])
  if (!imageLoaded) {
    return <Loading />
  }

  return (
    <Card className={classes.width95}>
      <Grow in>
        <CardContent>{store.isPractice && <PracticeChip />}</CardContent>
      </Grow>
      <Grow in>
        <CardContent>
          <Card>
            <CardContent>
              <CardMedia
                className={classes.face}
                image={q.target_image}
                title={q.target_name}
              />
            </CardContent>
            <Typography variant="h4" gutterBottom>
              {q.target_name}
            </Typography>
          </Card>
        </CardContent>
      </Grow>
      <Grow in>
        <CardActions className={classes.threeJobs} disableSpacing>
          <Fab
            className={classes.button}
            variant="extended"
            size="large"
            disabled={store.attemptNumber !== 0}
            onClick={store.incrementAttemptNumber}
          >
            {getString('fitsLabel')}
          </Fab>
          <Fab
            className={classes.button}
            variant="extended"
            size="large"
            disabled={store.attemptNumber !== 0}
            onClick={store.incrementAttemptNumber}
          >
            {getString('doesntFitLabel')}
          </Fab>
        </CardActions>
      </Grow>
      {timer && <Timer duration={timer} onTimeExpired={onTimeExpired} />}
    </Card>
  )
}

export default QuestionFNameLearn
