import React from 'react'
import { CheckCircle, IndeterminateCheckBox } from '@material-ui/icons'
import { green } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/styles'
import styles from '../css-styles'

const useStyles = makeStyles({ answerBadge: styles.answerBadge })

const Badge = ({ isCorrect }) => {
  const classes = useStyles()
  return isCorrect ? (
    <CheckCircle
      className={classes.answerBadge}
      fontSize="large"
      htmlColor={green[500]}
    />
  ) : (
    <IndeterminateCheckBox
      className={classes.answerBadge}
      fontSize="large"
      color="error"
    />
  )
}

export default Badge
