import React, { useContext, useEffect, useState } from 'react'
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormControl,
  CardContent,
  TextField,
  Grid,
  FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StoreContext } from '../../StoreContext'
import ButtonNext from '../ButtonNext'
import styles from '../../css-styles'

const useStyles = makeStyles({
  helperText: styles.helperText,
  margLeft: styles.margLeft,
  surveyText: styles.surveyText,
})

const MultiSelect = ({
  choices,
  includeNext,
  parentResponseHandler,
  multiSelectFollowup,
  skipNA,
}) => {
  const [checkBoxes, setCheckBoxes] = useState(false)
  const [selectedValues, setSelectedValues] = useState([])
  const [isDisabled, setIsDisabled] = useState(true)
  const [noneSelected, setNoneSelected] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [skipQuestion, setSkipQuestion] = useState([])
  const store = useContext(StoreContext)
  const { getString } = store
  const formRef = React.useRef()

  const classes = useStyles()
  const handleNext = () => {
    formRef.current.reportValidity()

    if (noneSelected) {
      setIsSubmitting(true)
      setIsDisabled(true)
      setSelectedValues([
        {
          value: 'None of the above',
          has_free_text: false,
          is_checked: true,
        },
      ])

      store.setUserResponse(selectedValues)
      store.setSkipSurveyQuestions([
        ...store.skipSurveyQuestions,
        ...skipQuestion,
      ])
      store.incrementAttemptNumber()
      store.setCallNextQuestion(true)
    } else if (selectedValues.length !== 0) {
      setIsSubmitting(true)
      const meetsRequirements = selectedValues.reduce(
        (cumulativeValue, currElement) => {
          // If any value in the selected list should have text input but does not, then we do not have validation
          return (
            cumulativeValue &&
            (!currElement.has_free_text ||
              (currElement.has_free_text &&
                currElement.text_input !== '' &&
                currElement.text_input !== undefined))
          )
        },
        true
      )
      if (meetsRequirements) {
        // We are validated and submitting
        // If the question selected has a followup-question, add it to our phase
        let currentFollowup = null
        selectedValues.forEach((val) => {
          // Update currentFollowup to be the first selected option with a followup question
          if (val.has_followup && currentFollowup === null) {
            currentFollowup = val.followup_question
          }
        })
        // If we have at least one followup_question and have selected more than one option, our followup becomes the mult-select
        if (selectedValues.length > 1 && currentFollowup) {
          currentFollowup = multiSelectFollowup
        }
        if (currentFollowup) {
          store.addQuestion(currentFollowup)
        }
        setIsDisabled(true)
        store.setUserResponse(selectedValues)
        store.setSkipSurveyQuestions([
          ...store.skipSurveyQuestions,
          ...skipQuestion,
        ])

        store.incrementAttemptNumber()
        store.setCallNextQuestion(true)
      } else {
        setIsSubmitting(false)
      }
    }
  }

  useEffect(() => {
    const handleTextInput = (position) => (event) => {
      selectedValues[position].text_input = event.target.value
    }

    const handleNoneSelect = () => {
      setIsDisabled(false)
      setNoneSelected(!noneSelected)
      setSelectedValues([])
      if (skipNA !== null) {
        setSkipQuestion([parseInt(skipNA, 10)])
      } else {
        setSkipQuestion([])
      }
      if (noneSelected && selectedValues.length === 0) {
        setIsDisabled(true)
      }
      // Send the value to the parent element. Used for followup questions
      if (includeNext !== undefined && !includeNext) {
        parentResponseHandler([])
      }
    }

    const updateSelectedValues = (value) => (event) => {
      setIsDisabled(false)
      const { checked } = event.target
      const skipID = event.target.getAttribute('data-skip-id')
      const resultObj = {
        value: value.value,
        has_free_text: value.has_free_text,
        is_checked: checked,
        skip_question: skipID,
        has_followup: 'followup_question' in value,
        followup_question:
          'followup_question' in value ? value.followup_question : null,
      }
      if (event.target.checked) {
        if (skipID) {
          setSkipQuestion([...skipQuestion, parseInt(skipID, 10)])
        }
        // Need a local copy of the result-arr for length value
        // selectedValues.length does not update in real time due to react state needing to rerender
        const valuesResultArr = [...selectedValues, resultObj]
        setSelectedValues(valuesResultArr)
      } else {
        setSelectedValues((arr) => {
          // Make an array of values from our objects. The position with the matching value should be removed
          const position = arr
            .map((e) => {
              return e.value
            })
            .indexOf(value.value)
          // Remove the value
          arr.splice(position, 1)
          // Return the new array
          return [...arr]
        })
        if (skipID) {
          const skipPosition = skipQuestion.indexOf(parseInt(skipID, 10))
          // Splice returns an array of removed objects
          // Don't call setSkipQuestion with the result of splice, it modifies the existing array
          skipQuestion.splice(skipPosition, 1)
          setSkipQuestion([...skipQuestion])
        }
        if (selectedValues.length === 0) {
          setIsDisabled(true)
        }
      }
      // Send the value to the parent element. Used for followup questions
      if (includeNext !== undefined && !includeNext) {
        parentResponseHandler(selectedValues)
      }
    }

    const order = []
    choices.forEach((pos) => {
      // Returns -1 if element is not selected
      // Otherwise returns position in the array
      const position = selectedValues
        .map((e) => {
          return e.value
        })
        .indexOf(pos.value)
      if (pos.has_free_text && position !== -1 && !noneSelected) {
        order.push(
          <>
            <Grid container direction="column" spacing={0}>
              <FormControlLabel
                classes={{ label: classes.surveyText }}
                checked
                value={pos.value}
                control={<Checkbox />}
                label={pos.value}
                key={pos.value}
                onChange={updateSelectedValues(pos)}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid container direction="column" spacing={0}>
              <TextField
                id={pos.value}
                helperText={pos.free_text_helper}
                FormHelperTextProps={{
                  classes: { root: classes.helperText },
                }}
                key={`TF-${pos.value}-reqs`}
                required
                onChange={handleTextInput(position)}
                size="small"
                variant="outlined"
                defaultValue={selectedValues[position].text_input}
                disabled={isSubmitting}
              />
            </Grid>
          </>
        )
      } else if (noneSelected) {
        // Here we push a label with a different key so all values are displayed as unchecked.
        order.push(
          <>
            <FormControlLabel
              classes={{ label: classes.surveyText }}
              control={<Checkbox />}
              label={pos.value}
              key={`TF-${pos.value}-delete`}
              disabled={noneSelected || isSubmitting}
              onChange={updateSelectedValues(pos)}
            />
            <FormHelperText
              className={`${classes.helperText} ${classes.margLeft}`}
            >
              {pos.free_text_helper}
            </FormHelperText>
          </>
        )
      } else {
        order.push(
          <>
            <FormControlLabel
              classes={{ label: classes.surveyText }}
              value={pos.value}
              control={
                <Checkbox
                  inputProps={{
                    'data-skip-id': pos.skip_question
                      ? pos.skip_question
                      : null,
                  }}
                />
              }
              label={pos.value}
              key={`TF-${pos.value}`}
              disabled={isSubmitting}
              onChange={updateSelectedValues(pos)}
            />
            <FormHelperText
              className={`${classes.helperText} ${classes.margLeft}`}
            >
              {pos.free_text_helper}
            </FormHelperText>
          </>
        )
      }
    })
    order.push(
      <FormControlLabel
        classes={{ label: classes.surveyText }}
        value="None of the Above"
        control={<Checkbox />}
        label={store.getString('noneOfTheAbove')}
        key="None of the Above"
        onChange={handleNoneSelect}
        disabled={isSubmitting}
      />
    )
    setCheckBoxes(order)
  }, [
    choices,
    selectedValues,
    classes.helperText,
    classes.margLeft,
    classes.surveyText,
    setSelectedValues,
    noneSelected,
    setNoneSelected,
    isSubmitting,
    store,
    includeNext,
    parentResponseHandler,
    skipQuestion,
    skipNA,
  ])

  const content = []
  const nextButton = []
  if (includeNext || includeNext === undefined) {
    content.push(
      <form ref={formRef}>
        <FormControl component="fieldset" fullWidth>
          <FormGroup>{checkBoxes}</FormGroup>
        </FormControl>
      </form>
    )
    nextButton.push(
      <ButtonNext onClick={handleNext} disable={isDisabled}>
        {getString('nextButtonLabel')}
      </ButtonNext>
    )
  } else {
    // Can't have nested form elements. Switch to div for followup questions
    content.push(
      <div ref={formRef}>
        <FormControl component="fieldset" fullWidth>
          <FormGroup>{checkBoxes}</FormGroup>
        </FormControl>
      </div>
    )
  }
  return (
    <>
      <CardContent>{content}</CardContent>
      {nextButton}
    </>
  )
}

export default MultiSelect
