import React, { useContext } from 'react'
import { Card, CardContent, Grow, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Timelapse } from '@material-ui/icons'
import { StoreContext } from '../StoreContext'
import styles from '../css-styles'

const useStyles = makeStyles({ fixed: styles.fixed, hearing: styles.hearing })

const AttemptedRecently = () => {
  const classes = useStyles()
  const store = useContext(StoreContext)

  return (
    <Grow in>
      <Card className={classes.fixed}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Please Wait
          </Typography>
          <Typography variant="body1" gutterBottom>
            You must wait 12 hours between each attempt.
          </Typography>
          <Timelapse className={classes.hearing} />
          <Typography
            variant="body1"
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: `You may attempt the test ${store.idAttemptedRecently.time_remaining}.`,
            }}
          />
        </CardContent>
      </Card>
    </Grow>
  )
}

export default AttemptedRecently
