import React, { useState, useContext } from 'react'
import { Card, Grow, Typography, CardContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Timer from './Timer'
import ButtonNext from './ButtonNext'
import { StoreContext } from '../StoreContext'
import styles from '../css-styles'

const useStyles = makeStyles({ fixed: styles.fixed })

const TimerExplanation = () => {
  const classes = useStyles()
  const [allowNext, setAllowNext] = useState(false)
  const [startTimer, setStartTimer] = useState(false)
  const { getString, getTimer, incrementPhase } = useContext(StoreContext)

  return (
    <Grow in>
      <Card className={classes.fixed}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {getString('timerExplanation')}
          </Typography>
        </CardContent>
        <CardContent>
          <Typography
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: getString('timerInstructions'),
            }}
          />
        </CardContent>
        {!allowNext ? (
          <ButtonNext
            onClick={() => {
              setStartTimer(true)
            }}
            disable={startTimer}
          >
            {getString('startTimerButtonLabel')}
          </ButtonNext>
        ) : null}
        {allowNext ? (
          <ButtonNext onClick={incrementPhase}>
            {getString('nextButtonLabel')}
          </ButtonNext>
        ) : null}
        {startTimer ? (
          <Timer
            duration={getTimer()}
            onTimeExpired={() => setAllowNext(true)}
          />
        ) : null}
      </Card>
    </Grow>
  )
}

export default TimerExplanation
