import React, { useContext } from 'react'
import { Card, CardContent, Typography } from '@material-ui/core'
import { StoreContext } from '../StoreContext'
import ButtonNext from './ButtonNext'

const AreYouThere = () => {
  const { getString, setShouldAskUserReady } = useContext(StoreContext)

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom>{getString('areYouThere')}</Typography>
      </CardContent>
      <ButtonNext onClick={() => setShouldAskUserReady(false)}>
        {getString('nextButtonLabel')}
      </ButtonNext>
    </Card>
  )
}

export default AreYouThere
