import React, { useContext } from 'react'
import {
  Card,
  CardMedia,
  CardContent,
  Grow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ButtonNext from './ButtonNext'
import { StoreContext } from '../StoreContext'
import styles from '../css-styles'

const useStyles = makeStyles({
  safariHeightFix: styles.safariHeightFix,
  heroImage: styles.heroImage,
})

const WelcomeScreen = () => {
  const classes = useStyles()
  const store = useContext(StoreContext)
  const { getString } = store
  const phase = store.getPhase(store.afterFeedback)
  const title = phase.instructions_title
  const body = phase.instructions_body
  const heroImgUrl = document.querySelector('#hero-image-url').dataset.imgUrl

  return (
    <Grow in>
      <Card className={classes.safariHeightFix}>
        <CardMedia className={classes.heroImage} image={heroImgUrl} />
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography gutterBottom dangerouslySetInnerHTML={{ __html: body }} />
        </CardContent>
        <ButtonNext onClick={store.incrementPhase}>
          {getString('beginButtonLabel')}
        </ButtonNext>
      </Card>
    </Grow>
  )
}

export default WelcomeScreen
