import React, { useContext } from 'react'
import { Card, CardContent, Grow, Typography } from '@material-ui/core'
import { StoreContext } from '../StoreContext'
import ButtonNext from './ButtonNext'
import { RC_CATEGORIES_LEARNING } from '../constants'

const Explanation = ({ onClick, reason }) => {
  const { didAnswerCorrectly, getString, getPhase, isPractice, afterFeedback } =
    useContext(StoreContext)
  const phase = getPhase(afterFeedback)

  return (
    <Grow in>
      <Card>
        <CardContent>
          {didAnswerCorrectly && (
            <Typography gutterBottom>
              {getString('explanationCorrect')}
            </Typography>
          )}
          <Typography gutterBottom>{reason}</Typography>
          {phase.react_component === RC_CATEGORIES_LEARNING && isPractice && (
            <Typography gutterBottom>
              {getString('explanationRemember')}
            </Typography>
          )}
        </CardContent>
        <ButtonNext onClick={onClick}>
          {getString('nextButtonLabel')}
        </ButtonNext>
      </Card>
    </Grow>
  )
}

export default Explanation
