import React, { useState, useContext } from 'react'
import { Fab } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StoreContext } from '../StoreContext'
import Badge from './Badge'
import {
  RC_GROCERIES_RECALL,
  RC_FACE_NAME_ASSOCIATE,
  RC_FACE_NAME_LEARN,
  RC_FACE_NAME_RECOGNIZE,
  RC_FNAME_MATCHING,
} from '../constants'

const useStyles = makeStyles({
  button: {
    minWidth: '15rem',
    fontSize: 'calc(1rem + 1vw)',
  },
})

const AnswerButtonTrio = ({ answer }) => {
  const classes = useStyles()
  const [attempted, setAttempted] = useState(false)
  const store = useContext(StoreContext)
  const phase = store.getPhase(store.afterFeedback)

  const shouldAdvanceQuestion =
    (phase.react_component === RC_GROCERIES_RECALL &&
      store.isPractice &&
      answer.isCorrect) ||
    (store.isPractice &&
      phase.react_component === RC_FACE_NAME_ASSOCIATE &&
      answer.isCorrect) ||
    (!store.isPractice && phase.react_component === RC_FACE_NAME_ASSOCIATE) ||
    [RC_FACE_NAME_LEARN, RC_FACE_NAME_RECOGNIZE].includes(
      phase.react_component
    ) ||
    (phase.react_component === RC_FNAME_MATCHING &&
      store.isPractice &&
      answer.isCorrect) ||
    (phase.react_component === RC_FNAME_MATCHING && !store.isPractice) ||
    (phase.react_component === RC_GROCERIES_RECALL && !store.isPractice)

  return (
    <Fab
      className={classes.button}
      variant="extended"
      size="large"
      disabled={attempted}
      onClick={() => {
        setAttempted(true)
        store.setDidAnswerCorrectly(answer.isCorrect)
        store.incrementAttemptNumber()
        if (shouldAdvanceQuestion) {
          store.nextQuestionWithShortDelay()
        }
      }}
    >
      {answer.value}
      {store.hasVisualFeedback && attempted && (
        <Badge isCorrect={answer.isCorrect} />
      )}
    </Fab>
  )
}

export default AnswerButtonTrio
