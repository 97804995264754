import React, { useContext } from 'react'
import { Card, CardContent, CardActions, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PracticeChip from '../PracticeChip'
import AnswerButtonTrio from '../AnswerButtonTrio'
import Timer from '../Timer'
import { StoreContext } from '../../StoreContext'
import styles from '../../css-styles'

const useStyles = makeStyles({ threeJobs: styles.threeJobs })

const Question = ({ q }) => {
  const classes = useStyles()
  const store = useContext(StoreContext)

  const onTimeExpired = () =>
    store.didAnswerCorrectly === null
      ? store.askUserAreYouThere
      : store.onTimeExpired

  let label = `${q.person} job is:`

  if (store.language === 'es') {
    label = `Ocupación de ${q.person} es:`
  }

  return (
    <Card>
      <CardContent>
        {store.isPractice && <PracticeChip />}
        <Typography variant="h4">{label}</Typography>
      </CardContent>
      <CardActions className={classes.threeJobs} disableSpacing>
        <AnswerButtonTrio
          answer={{ value: q.job1, isCorrect: q.correct === 1 }}
        />
        <AnswerButtonTrio
          answer={{ value: q.job2, isCorrect: q.correct === 2 }}
        />
        <AnswerButtonTrio
          answer={{ value: q.job3, isCorrect: q.correct === 3 }}
        />
      </CardActions>
      <Timer duration={store.getTimer()} onTimeExpired={onTimeExpired()} />
    </Card>
  )
}

export default Question
