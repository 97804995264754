import React, { useContext } from 'react'
import { StoreContext } from '../../StoreContext'
import TestInstructions from '../TestInstructions'
import QuestionFaceNameLearn from './QuestionFaceNameLearn'
import QuestionFaceNameAssociate from './QuestionFaceNameAssociate'
import QuestionFaceNameRecognize from './QuestionFaceNameRecognize'
import useQuestionStartTime from '../../hooks/useQuestionStartTime'
import {
  RC_FACE_NAME_RECOGNIZE,
  RC_FACE_NAME_LEARN,
  RC_FACE_NAME_ASSOCIATE,
} from '../../constants'

const Test = () => {
  const store = useContext(StoreContext)
  const phase = store.getPhase(store.afterFeedback)
  useQuestionStartTime()

  if (store.location.question) {
    // eslint-disable-next-line camelcase
    const { react_component } = phase
    const question = store.getQuestion(store.afterFeedback)
    // eslint-disable-next-line camelcase
    switch (react_component) {
      case RC_FACE_NAME_LEARN:
        return <QuestionFaceNameLearn q={question} />
      case RC_FACE_NAME_ASSOCIATE:
        return <QuestionFaceNameAssociate q={question} />
      case RC_FACE_NAME_RECOGNIZE:
        return <QuestionFaceNameRecognize q={question} />
      default:
        throw new Error(store.getString('genericError'))
    }
  }

  // Some phases have no instructions, skip to Question 1 in this case
  if (phase.instructions_body === '' && phase.instructions_title === '') {
    store.nextQuestion()
  }

  return (
    <TestInstructions
      key={store.location.question}
      title={phase.instructions_title}
      body={phase.instructions_body}
    />
  )
}

export default Test
