import React from 'react'
import PropTypes from 'prop-types'
import { Button, CardActions } from '@material-ui/core'
import { NavigateNext } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import styles from '../css-styles'

const useStyles = makeStyles({
  justifyFlexEnd: styles.justifyFlexEnd,
  marginRight: styles.marginRight,
})

const ButtonNext = ({ children, disable, onClick }) => {
  const classes = useStyles()
  return (
    <CardActions className={classes.justifyFlexEnd}>
      <Button
        onClick={onClick}
        size="large"
        variant="contained"
        color="primary"
        className={classes.marginRight}
        disabled={disable}
      >
        {children}
        <NavigateNext fontSize="large">{children}</NavigateNext>
      </Button>
    </CardActions>
  )
}

ButtonNext.defaultProps = {
  disable: false,
}

ButtonNext.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  disable: PropTypes.bool,
}

export default ButtonNext
