import React, { useContext } from 'react'
import { Chip } from '@material-ui/core/'
import { makeStyles } from '@material-ui/styles'
import { StoreContext } from '../StoreContext'
import styles from '../css-styles'

const useStyles = makeStyles({
  picMatchingContainer: styles.picMatchingContainer,
})

const PracticeChip = () => {
  const { getString } = useContext(StoreContext)
  const classes = useStyles()
  return (
    <div className={classes.picMatchingContainer}>
      <Chip
        label={getString('practiceChip')}
        variant="outlined"
        style={{
          fontSize: '1.25rem',
          minWidth: '10rem',
          color: 'white',
          backgroundColor: '#ff9100',
        }}
      />
    </div>
  )
}

export default PracticeChip
