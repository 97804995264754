import React, { useContext, useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Grow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PracticeChip from '../PracticeChip'
import AnswerButton from './AnswerButton'
import { StoreContext } from '../../StoreContext'
import useQuestionStartTime from '../../hooks/useQuestionStartTime'
import styles from '../../css-styles'
import Loading from '../Loading'

const useStyles = makeStyles({
  width95: styles.width95,
  signHero: styles.signHero,
  justifySpaceEvenly: styles.justifySpaceEvenly,
})

const SignQuestion = ({ sign }) => {
  const classes = useStyles()
  useQuestionStartTime()
  const { getString, isPractice } = useContext(StoreContext)
  const [imageLoaded, setImageLoaded] = useState(true)
  const [sleepCounter, setSleepCounter] = useState(0)
  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements
  const [preloadedImage] = useState(new Image())
  if (preloadedImage.src === '') {
    preloadedImage.src = sign.image
  }
  // Once dummyImage.complete is updated, this will fire and remove loading screen.
  useEffect(() => {
    // Every second increment a counter to check if the image is done loading.
    async function incrementSleep(duration) {
      await new Promise((r) => setTimeout(r, duration))
      setSleepCounter(sleepCounter + 1)
    }
    if (imageLoaded && preloadedImage.complete) {
      return
    }
    if (preloadedImage.complete) {
      setImageLoaded(preloadedImage.complete)
    } else {
      setImageLoaded(false)
      const sleepDuration = sleepCounter >= 10 ? 1000 : 100
      incrementSleep(sleepDuration)
    }
  }, [imageLoaded, setImageLoaded, preloadedImage.complete, sleepCounter])
  if (!imageLoaded) {
    return <Loading />
  }

  return (
    <Card className={classes.width95}>
      <CardContent>
        {isPractice && <PracticeChip />}
        <Typography>{getString('sawSign')}</Typography>
      </CardContent>
      <Grow in>
        <CardMedia className={classes.signHero} image={sign.image} />
      </Grow>
      <CardActions className={classes.justifySpaceEvenly} disableSpacing>
        <AnswerButton
          answer={{ text: getString('yes'), isCorrect: sign.is_correct }}
        />
        <AnswerButton
          answer={{ text: getString('no'), isCorrect: !sign.is_correct }}
        />
      </CardActions>
    </Card>
  )
}

export default SignQuestion
