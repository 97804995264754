import { useEffect, useContext } from 'react'
import { StoreContext } from '../StoreContext'

const useQuestionStartTime = () => {
  const store = useContext(StoreContext)
  const { setQuestionStartTime } = store

  useEffect(() => {
    setQuestionStartTime(performance.now())
  }, [setQuestionStartTime])
}

export default useQuestionStartTime
