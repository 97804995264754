import React, { useContext, useState } from 'react'
import { CardContent, TextField } from '@material-ui/core'
import { StoreContext } from '../../StoreContext'
import ButtonNext from '../ButtonNext'

const TextFieldComp = () => {
  const store = useContext(StoreContext)
  const { getString } = store
  const [formInput, setFormInput] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const handleNext = () => {
    if (formInput !== '') {
      setIsDisabled(true)
      store.setUserResponse(formInput)
      store.incrementAttemptNumber()
      store.nextQuestionWithShortDelay()
    }
  }

  return (
    <>
      <CardContent>
        <TextField
          required
          autoComplete="off"
          id="free_response"
          value={formInput}
          margin="normal"
          fullWidth
          variant="outlined"
          onChange={(e) => {
            setIsDisabled(false)
            setFormInput(e.target.value)
          }}
          onFocus={() => {
            setTimeout(() => window.scrollTo(0, 40), 333)
          }}
          inputProps={{ maxLength: 512, size: 'large' }}
          multiline
        />
      </CardContent>
      <ButtonNext onClick={handleNext} disable={isDisabled}>
        {getString('nextButtonLabel')}
      </ButtonNext>
    </>
  )
}

export default TextFieldComp
