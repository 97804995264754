import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme()

const styles = {
  icon: {
    fontSize: '5rem',
    display: 'block',
    marginTop: '1rem',
    marginRight: 'auto',
    marginBottom: '1rem',
    marginLeft: 'auto',
  },
  threeJobsAnswerButton: {
    minWidth: '15rem',
    fontSize: 'calc(1rem + 1vw)',
  },
  width95: {
    minWidth: '20rem',
    maxWidth: '96vw',
  },
  fNameFaceHero: {
    height: 'calc(var(--rvh, 1vh) * 50)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    borderRadius: '4px',
  },
  faceHero: {
    height: 'calc(var(--rvh, 1vh) * 30)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    borderRadius: '4px',
  },
  threeJobs: {
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    gridGap: '1.5rem',
    margin: '0 1rem 1rem 1rem',
  },
  answerImg: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'white',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderRadius: '4px',
  },
  answerImgDisabled: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'white',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderRadius: '4px',
    transition: 'opacity 0.33s',
    opacity: 0.3,
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  yesNoAnswerButton: {
    margin: '1rem',
    height: 'calc(6rem + 3vw)',
    minWidth: 'calc(8rem + 4vw)',
    fontSize: 'calc(1.5rem + 2vw)',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignStart: {
    textAlign: 'start',
  },
  picMatchingContainer: {
    display: 'grid',
    gridGap: '1rem',
    justifyItems: 'center',
    alignItems: 'center',
  },
  justifySpaceEvenly: {
    justifyContent: 'space-evenly',
  },
  signHero: {
    height: 'calc(var(--rvh, 1vh) * 33)',
    backgroundSize: 'contain',
  },
  digitSignImage: {
    height: 'calc(var(--rvh, 1vh) * 12)',
    backgroundSize: 'contain',
  },
  digitSignImageTarget: {
    height: 'calc(var(--rvh, 1vh) * 20)',
    backgroundSize: 'contain',
  },
  picMatchingImage: {
    height: 'calc(var(--rvh, 1vh) * 33)',
    width: '95%',
  },
  justifyFlexEnd: {
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  marginRight: {
    marginRight: '0.5rem',
  },
  fixed: {
    width: '96vw',
    // height: 'calc(var(--rvh, 1vh) * 96)',
    // maxHeight: '640px',
    display: 'grid',
    maxWidth: '800px',
    gridTemplateRows: 'min-content min-content min-content',
  },
  hearing: {
    fontSize: '5rem',
    display: 'block',
    margin: 'auto',
  },
  heroImage: {
    height: 'calc(var(--rvh, 1vh) * 35)',
    flexShrink: '1',
  },
  questionImage: {
    height: '10rem',
    backgroundSize: 'contain',
  },
  displayBlockMarginAuto: {
    display: 'block',
    marginRight: 'auto',
    marginBottom: '1rem',
    marginLeft: 'auto',
  },
  marginXAuto: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  safariHeightFix: {
    height: 'calc(var(--rvh, 1vh) * 96)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  answerGrid4x4: {
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '2rem',
  },
  answerButton: {
    lineHeight: '50%',
    height: 'calc(6rem + 4vw)',
    width: 'calc(6rem + 4vw)',
    fontSize: 'calc(2rem + 2vw)',
  },
  answerBadge: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  stimuli: {
    fontSize: 'calc(3rem + 3vw)',
  },
  helperText: {
    fontSize: 'calc(1rem + .025vw)',
    color: '#A1A1A1',
  },
  margLeft: {
    marginLeft: '1rem',
  },
  selectAllText: {
    color: 'grey',
  },
  surveyText: {
    fontSize: 'calc(1rem + .75vw)',
    textAlign: 'start',
  },
  avatarLarge: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    fontSize: '3rem',
  },
  digitSignNumberOutline: {
    backgroundColor: 'white',
    borderColor: 'black',
    borderRadius: '50%',
    borderWidth: '2px',
    border: 'solid',
    color: 'black',
  },
  digitSignTimerPrimary: {
    backgroundColor: 'green',
  },
  digitSignTimerSecondary: {
    backgroundColor: 'lightgreen',
  },
  consentContainer: {
    marginBottom: '2rem',
    overflowY: 'auto',
    maxHeight: '40vh',
    overflowX: 'hidden',
  },
  marginBottom1: {
    marginBottom: '1rem',
  },
  paddingX1: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
}

export { styles as default }
