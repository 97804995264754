import React, { useState, useReducer, useEffect, useContext } from 'react'
import { Card, CardContent, Grow, Link, Typography } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { green } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/styles'
import { StoreContext } from '../StoreContext'
import SavingProgress from './SavingProgress'
import { submit } from '../utils'
import styles from '../css-styles'
import AssignmentFeedback from './AssignmentFeedback'
import ButtonNext from './ButtonNext'

const useStyles = makeStyles({ icon: styles.icon })

const increment = (state) => state + 0.3

const survey = () => {
  const surveyURL = 'https://redcap.partners.org/redcap/surveys/?s=M3KXFNK4EF'
  return (
    <Typography gutterBottom>
      Please take our <Link href={surveyURL}>survey</Link>.
    </Typography>
  )
}

const ThankYou = () => {
  const [percentComplete, incrementPercentComplete] = useReducer(increment, 0)
  const [timerID, setTimerID] = useState(null)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const store = useContext(StoreContext)
  const phase = store.getPhase(store.afterFeedback)
  const title = phase.instructions_title
  const body = phase.instructions_body
  const classes = useStyles()
  const { generateSubmission } = store

  // POST results to API
  useEffect(() => {
    // Assumption: The last phase is the save screen
    if (!hasSubmitted && percentComplete > 50) {
      submit(generateSubmission({ isComplete: true }))
      setHasSubmitted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSubmitted, percentComplete])

  // Setup interval to increment % complete
  useEffect(() => {
    if (!timerID && percentComplete < 100) {
      setTimerID(setInterval(incrementPercentComplete, 20))
    }
  }, [percentComplete, timerID])

  // Stop interval at 100%
  useEffect(() => {
    if (percentComplete >= 100) {
      clearInterval(timerID)
      setTimerID(null)
    }
  }, [percentComplete, timerID])

  if (percentComplete < 100) {
    return <SavingProgress progress={percentComplete} />
  }
  let feedback = <></>
  if (
    store.assignment &&
    store.assignment.displayFeedback &&
    (store.assignment.testCount === store.assignment.testNumber ||
      store.assignment.isComplete)
  ) {
    feedback = <AssignmentFeedback />
  }

  return (
    <Grow in>
      <Card>
        <CardContent>
          <Typography variant="h4">{title}</Typography>
          <CheckCircle
            className={classes.icon}
            fontSize="large"
            htmlColor={green[500]}
          />
          {store.giveSurvey ? survey() : <Typography>{body}</Typography>}
          {feedback}
          {store.assignment &&
          store.assignment.displayFeedback &&
          store.data.postFeedbackPhases.length > 0 &&
          !store.afterFeedback ? (
            <ButtonNext
              onClick={() => {
                store.startPostFeedbackPhases()
              }}
            >
              {store.getString('nextButtonLabel')}
            </ButtonNext>
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
    </Grow>
  )
}

export default ThankYou
