import React, { useContext } from 'react'
import { Fab } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StoreContext } from '../../StoreContext'
import styles from '../../css-styles'

const useStyles = makeStyles({ yesNoAnswerButton: styles.yesNoAnswerButton })

const CorrectPairButton = ({ children, isCorrect, setAnswered }) => {
  const classes = useStyles()
  const store = useContext(StoreContext)

  return (
    <Fab
      className={classes.yesNoAnswerButton}
      variant="extended"
      size="large"
      disabled={store.attemptNumber !== 0}
      onClick={() => {
        setAnswered(true)
        store.incrementAttemptNumber()
        store.setDidAnswerCorrectly(isCorrect)
      }}
    >
      {children}
    </Fab>
  )
}

export default CorrectPairButton
