import React, { useState, useContext } from 'react'
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grow,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StoreContext } from '../StoreContext'
import styles from '../css-styles'

const useStyles = makeStyles({
  width95: styles.width95,
  justifyFlexEnd: styles.justifyFlexEnd,
})

const RequestID = () => {
  const classes = useStyles()
  const store = useContext(StoreContext)
  const { getString } = store
  const { setUserProvidedID } = useContext(StoreContext)
  const [code, setCode] = useState('')

  return (
    <Grow in>
      <Card className={classes.width95}>
        <CardContent>
          <Typography>{getString('requestId')}</Typography>
          <form
            noValidate
            autoComplete="off"
            spellCheck="false"
            autoCorrect="off"
            autoCapitalize="off"
          >
            <TextField
              required
              id="required"
              label="Required"
              value={code}
              margin="normal"
              variant="outlined"
              fullWidth
              onChange={(e) => setCode(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  setUserProvidedID(code)
                }
              }}
            />
            <CardActions className={classes.justifyFlexEnd}>
              <Button
                onClick={() => setUserProvidedID(code)}
                variant="contained"
                color="primary"
              >
                {getString('submitButtonLabel')}
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </Grow>
  )
}

export default RequestID
