import React, { useContext, useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Grow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import AnswerButton from '../Signs/AnswerButton'
import { StoreContext } from '../../StoreContext'
import styles from '../../css-styles'
import Loading from '../Loading'

const useStyles = makeStyles({
  signHero: styles.signHero,
  justifySpaceEvenly: styles.justifySpaceEvenly,
})

const CorrectPair = ({ q }) => {
  const classes = useStyles()
  const { getString } = useContext(StoreContext)
  const [imageLoaded, setImageLoaded] = useState(true)
  const [sleepCounter, setSleepCounter] = useState(0)
  const [preloadedImage] = useState(new Image())
  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements
  if (preloadedImage.src === '') {
    preloadedImage.src = q.image
  }
  // Once dummyImage.complete is updated, this will fire and remove loading screen.
  useEffect(() => {
    // Every second increment a counter to check if the image is done loading.
    async function incrementSleep(duration) {
      await new Promise((r) => setTimeout(r, duration))
      setSleepCounter(sleepCounter + 1)
    }
    if (imageLoaded && preloadedImage.complete) {
      return
    }
    if (preloadedImage.complete) {
      setImageLoaded(preloadedImage.complete)
    } else {
      setImageLoaded(false)
      const sleepDuration = sleepCounter >= 10 ? 1000 : 100
      incrementSleep(sleepDuration)
    }
  }, [imageLoaded, setImageLoaded, preloadedImage.complete, sleepCounter])
  if (!imageLoaded) {
    return <Loading />
  }
  return (
    <Card>
      <CardContent>
        <Typography>{getString('wasCorrectPair')}</Typography>
      </CardContent>
      <Grow in>
        <CardContent>
          <Card>
            <CardContent>
              <CardMedia
                className={classes.signHero}
                image={q.image}
                title={q.name}
              />
            </CardContent>
            <Typography variant="h4" gutterBottom>
              ${q.price}
            </Typography>
          </Card>
        </CardContent>
      </Grow>
      <CardActions className={classes.justifySpaceEvenly}>
        <AnswerButton
          answer={{ text: getString('yes'), isCorrect: q.is_correct }}
        />
        <AnswerButton
          answer={{ text: getString('no'), isCorrect: !q.is_correct }}
        />
      </CardActions>
    </Card>
  )
}
export default CorrectPair
