import React, { useContext } from 'react'
import {
  Card,
  CardContent,
  CircularProgress,
  Grow,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StoreContext } from '../StoreContext'
import styles from '../css-styles'

const useStyles = makeStyles({
  displayBlockMarginAuto: styles.displayBlockMarginAuto,
})

const SavingProgress = ({ progress }) => {
  const { getString } = useContext(StoreContext)
  const classes = useStyles()
  return (
    <Grow in>
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {getString('saving')}
          </Typography>
          <CircularProgress
            className={classes.displayBlockMarginAuto}
            variant="determinate"
            size="10vh"
            thickness={2}
            value={progress}
          />
          <Typography gutterBottom>{getString('doNotCloseTab')}</Typography>
        </CardContent>
      </Card>
    </Grow>
  )
}

export default SavingProgress
