import React, { useContext, useState, useEffect, useReducer } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { StoreContext } from '../StoreContext'

const incrementor = (state) => state + 0.5

const CircularDeterminate = ({ callback, timer, size = 26 }) => {
  const [timerId, setTimerId] = useState(null)
  const [percentComplete, incrementPercentComplete] = useReducer(incrementor, 0)
  const { getTimer } = useContext(StoreContext)
  const timerSeconds = getTimer(timer) * 5

  useEffect(() => {
    if (timerId === null) {
      const id = setInterval(() => {
        incrementPercentComplete()
      }, timerSeconds)
      setTimerId(id)
    }
    return () => clearInterval(timerId)
  }, [timer, timerId, timerSeconds])

  useEffect(() => {
    if (percentComplete >= 100) {
      clearInterval(timerId)
      callback()
    }
  })

  return (
    <CircularProgress
      variant="determinate"
      value={percentComplete}
      size={size}
    />
  )
}
export default CircularDeterminate
