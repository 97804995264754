import React, { useContext, useEffect, useState } from 'react'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  CardContent,
  TextField,
  FormHelperText,
  Typography,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'
import { StoreContext } from '../../StoreContext'
import ButtonNext from '../ButtonNext'
import styles from '../../css-styles'
import { SURVEY_SLIDER, SURVEY_MC, SURVEY_MS } from '../../constants'
import SliderComp from './SliderComp'
import MultiSelect from './MultiSelect'

const useStyles = makeStyles({
  helperText: styles.helperText,
  margLeft: styles.margLeft,
  surveyText: styles.surveyText,
  marginBottom1: styles.marginBottom1,
  textAlignStart: styles.textAlignStart,
  selectAllText: styles.selectAllText,
})

const MultipleChoice = ({ choices, includeNext, parentResponseHandler }) => {
  const [radioButtons, setRadioButtons] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null)
  const [textInput, setTextInput] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const [hasTextInput, setHasTextInput] = useState(false)
  const [followupResponse, setFollowupResponse] = useState(null)
  const [hasFollowupTextInput, setHasFollowupTextInput] = useState(false)
  const [followupTextInput, setFollowupTextInput] = useState(null)
  const [followupElems, setFollowupElems] = useState([])
  const [skipSurveyID, setSkipSurveyID] = useState()
  const store = useContext(StoreContext)
  const { getString } = store
  const formRef = React.useRef()

  const classes = useStyles()

  const handleRadioChange = (event) => {
    const idToSkip = event.target.getAttribute('data-skip-id')
    if (idToSkip) {
      setSkipSurveyID(parseInt(idToSkip, 10))
    } else {
      setSkipSurveyID(null)
    }
    setSelectedValue(event.target.value)
    setFollowupResponse(null)
    // Send the value to the parent element. Used for followup questions
    if (includeNext !== undefined && !includeNext) {
      parentResponseHandler(event.target.value, hasTextInput, textInput)
    }
    if (!hasTextInput || (hasTextInput && textInput !== '')) {
      setIsDisabled(false)
    }
  }

  const handleNext = () => {
    const validity = formRef.current.reportValidity()
    if (validity && (!hasTextInput || (hasTextInput && textInput !== ''))) {
      setIsDisabled(true)
      store.setUserResponse(selectedValue)
      store.setHasTextInput(hasTextInput)
      store.setTextInput(textInput)
      const hasFollowup = followupResponse !== null
      store.setHasFollowUpQuestion(hasFollowup)
      store.setFollowupResponse(followupResponse)
      store.setHasFollowupTextInput(hasFollowupTextInput)
      store.setFollowupTextInput(followupTextInput)
      store.setSkipSurveyQuestions([...store.skipSurveyQuestions, skipSurveyID])
      store.incrementAttemptNumber()
      store.nextQuestionWithShortDelay()
    }
  }

  useEffect(() => {
    const handleResponseChange = (responseSelected, hasText, text) => {
      formRef.current.reportValidity()
      setFollowupResponse(responseSelected)
      setHasFollowupTextInput(hasText)
      setFollowupTextInput(text)
    }

    const handleTextInput = (event) => {
      setTextInput(event.target.value)
      if (includeNext !== undefined && !includeNext) {
        parentResponseHandler(selectedValue, hasTextInput, event.target.value)
      }
    }

    const updateHasTextInput = (value) => () => {
      setHasTextInput(value)
    }
    const order = []
    choices.forEach((pos) => {
      const followupQuestions = []
      if (pos.followup_question !== undefined) {
        const followupQuestionType = pos.followup_question.question_type
        if (followupQuestionType === SURVEY_SLIDER) {
          followupQuestions.push(
            <>
              <Typography className={classes.textAlignStart}>
                {pos.followup_question.question_text}
              </Typography>
              <SliderComp
                min={pos.followup_question.options.min}
                max={pos.followup_question.options.max}
                step={pos.followup_question.step}
                hasNotApplicable={
                  pos.followup_question.options.has_not_applicable
                }
                minLabel={pos.followup_question.options.min_label}
                maxLabel={pos.followup_question.options.max_label}
                key={`followup-slider-${pos.id}`}
                includeNext={false}
                parentResponseHandler={handleResponseChange}
              />
            </>
          )
        } else if (followupQuestionType === SURVEY_MC) {
          followupQuestions.push(
            <>
              <Typography className={classes.textAlignStart}>
                {pos.followup_question.question_text}
              </Typography>
              <MultipleChoice
                choices={pos.followup_question.options.choices}
                key={`followup-mc-${pos.id}`}
                includeNext={false}
                parentResponseHandler={handleResponseChange}
              />
            </>
          )
        } else if (followupQuestionType === SURVEY_MS) {
          followupQuestions.push(
            <>
              <Typography className={classes.textAlignStart}>
                {pos.followup_question.question_text}
              </Typography>
              <Typography
                variant="body2"
                className={[classes.selectAllText, classes.textAlignStart]}
                align="center"
              >
                {store.getString('selectAllApply')}
              </Typography>
              <MultiSelect
                choices={pos.followup_question.options.choices}
                key={`followup-ms-${pos.id}`}
                includeNext={false}
                parentResponseHandler={handleResponseChange}
              />
            </>
          )
        }
      }
      if (pos.followup_question !== undefined && selectedValue === pos.value) {
        setFollowupElems([
          <FormControl
            key={`form-item-${pos.followup_question.id}`}
            component="fieldset"
          >
            {followupQuestions}
          </FormControl>,
        ])
      }
      // Check for free text field OR followup question. Also check if the value is selected
      if (pos.has_free_text && selectedValue === pos.value) {
        order.push(
          <>
            <div style={{ alignItems: 'stretch' }} key={`div-${pos.value}`}>
              <FormControlLabel
                classes={{ label: classes.surveyText }}
                value={pos.value}
                control={<Radio required />}
                label={pos.value}
                key={pos.value}
                onChange={updateHasTextInput(pos.has_free_text)}
              />
              <TextField
                id={pos.value}
                key={pos.value + pos.has_free_text}
                helperText={pos.free_text_helper}
                FormHelperTextProps={{
                  classes: { root: classes.helperText },
                }}
                required
                onChange={handleTextInput}
                size="small"
                variant="outlined"
                defaultValue={textInput}
              />
            </div>
          </>
        )
      } else {
        order.push(
          <>
            <FormControlLabel
              value={pos.value}
              classes={{ label: classes.surveyText }}
              control={
                <Radio
                  required
                  inputProps={{
                    'data-skip-id': pos.skip_question
                      ? pos.skip_question
                      : null,
                  }}
                />
              }
              label={pos.value}
              key={pos.value}
              onChange={updateHasTextInput(pos.has_free_text)}
            />
            <FormHelperText
              className={`${classes.helperText} ${classes.margLeft}`}
            >
              {pos.free_text_helper}
            </FormHelperText>
          </>
        )
      }
    })

    setRadioButtons(order)
  }, [
    choices,
    textInput,
    setTextInput,
    selectedValue,
    followupResponse,
    hasTextInput,
    includeNext,
    parentResponseHandler,
    store,
    classes.helperText,
    classes.margLeft,
    classes.surveyText,
    classes.selectAllText,
    classes.textAlignStart,
  ])
  const nextButton = []
  const content = []
  if (includeNext || includeNext === undefined) {
    content.push(
      <form ref={formRef} key="MC-Form">
        <FormControl
          key={9}
          component="fieldset"
          className={classes.marginBottom1}
        >
          <RadioGroup name="MC-Parent" onChange={handleRadioChange}>
            {radioButtons}
          </RadioGroup>
        </FormControl>
        {followupElems}
      </form>
    )
    nextButton.push(
      <ButtonNext key={10} onClick={handleNext} disable={isDisabled}>
        {getString('nextButtonLabel')}
      </ButtonNext>
    )
  } else {
    // Can't have nested form elements. Switch to div for followup questions
    content.push(
      <>
        <FormControl component="fieldset" key="MC-Form-Followup">
          <RadioGroup name="MC-Child" onChange={handleRadioChange}>
            {radioButtons}
          </RadioGroup>
        </FormControl>
      </>
    )
  }

  return (
    <>
      <CardContent>{content}</CardContent>
      {nextButton}
    </>
  )
}

export default MultipleChoice
