import React, { useContext } from 'react'
import { Fab } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StoreContext } from '../../StoreContext'
import styles from '../../css-styles'

const useStyles = makeStyles({
  threeJobsAnswerButton: styles.threeJobsAnswerButton,
})

const ConfidenceButton = ({ answered, confident, setAnswered }) => {
  const classes = useStyles()
  const { getString, recordConfidenceLevel, nextQuestionWithShortDelay } =
    useContext(StoreContext)

  const label = confident ? getString('confident') : getString('notConfident')

  return (
    <Fab
      className={classes.threeJobsAnswerButton}
      variant="extended"
      size="large"
      disabled={answered}
      onClick={() => {
        setAnswered(true)
        recordConfidenceLevel(confident === true)
        nextQuestionWithShortDelay()
      }}
    >
      {label}
    </Fab>
  )
}

export default ConfidenceButton
