import React, { useContext } from 'react'
import { StoreContext } from '../../StoreContext'
import TestInstructions from '../TestInstructions'
import Learn from './Learn'
import PicMatching from './PicMatching'
import Recall from './Recall'
import CorrectPair from './CorrectPair'
import useQuestionStartTime from '../../hooks/useQuestionStartTime'
import {
  RC_GROCERIES_PAIR,
  RC_GROCERIES_LEARN,
  RC_GROCERIES_PICMATCHING,
  RC_GROCERIES_RECALL,
} from '../../constants'

const Groceries = () => {
  const store = useContext(StoreContext)
  const { getPhase, getQuestion, location, afterFeedback } = store
  const phase = getPhase(afterFeedback)
  useQuestionStartTime()

  if (location.question === 0) {
    return (
      <TestInstructions
        title={phase.instructions_title}
        body={phase.instructions_body}
      />
    )
  }

  if (phase.react_component === RC_GROCERIES_PAIR) {
    return <CorrectPair q={getQuestion()} key={location.question} />
  }

  if (phase.react_component === RC_GROCERIES_LEARN) {
    return <Learn q={getQuestion()} key={location.question} />
  }
  if (phase.react_component === RC_GROCERIES_PICMATCHING) {
    return <PicMatching q={getQuestion()} key={location.question} />
  }
  if (phase.react_component === RC_GROCERIES_RECALL) {
    return <Recall q={getQuestion()} key={location.question} />
  }

  return 'Groceries.jsx fell through!'
}

export default Groceries
