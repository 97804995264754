import React, { useContext, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import ReactMarkdown from 'react-markdown'

import { makeStyles } from '@material-ui/styles'
import * as Sentry from '@sentry/browser'
import { StoreContext } from '../../StoreContext'
import styles from '../../css-styles'

const useStyles = makeStyles({
  justifySpaceEvenly: styles.justifySpaceEvenly,
  marginXAuto: styles.marginXAuto,
  consentContainer: styles.consentContainer,
  width95: styles.width95,
  surveyText: styles.surveyText,
  marginBottom1: styles.marginBottom1,
})

const ConsentForm = () => {
  const store = useContext(StoreContext)
  const classes = useStyles()
  const { getString } = store
  const [formInput, setFormInput] = useState('')
  const [userConsented, setUserConsented] = useState('')
  const [error, setError] = useState(false)
  const formRef = React.useRef()
  const handleFormSubmit = (event) => {
    event.preventDefault()
    formRef.current.reportValidity()
    if (userConsented === '') {
      setError(true)
    } else {
      const formData = new FormData()
      formData.append('user_provided_id', store.userProvidedID)
      formData.append('user_provided_email', formInput)
      formData.append('user_consent', userConsented)
      if (store.testVariationId !== null) {
        formData.append('test_variation_id', store.testVariationId)
      }
      if (store.assignmentId !== null) {
        formData.append('assignment_id', store.assignmentId)
      }
      if (store.sequenceId !== null) {
        formData.append('sequence_id', store.sequenceId)
      }

      fetch(`/test/${store.organization}/api/v2/submit-consent/`, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((json) => {
          store.setUserConsented(json.consented)
        })
        .catch((err) => {
          Sentry.captureException(err)
          const newErr = new Error(
            'Something went wrong while checking the ID.'
          )
          store.setErrorState({ type: 'error', error: newErr })
        })
    }
  }
  const handleRadioChange = (event) => {
    setUserConsented(event.target.value)
    setError(false)
  }
  return (
    <div className={classes.marginBottom1}>
      <Typography variant="h4" align="center" className={classes.marginBottom1}>
        {getString('consentForm')}
      </Typography>
      <Card className={classes.marginBottom1}>
        <CardContent>
          <ReactMarkdown>{store.consentMarkdown}</ReactMarkdown>
        </CardContent>
      </Card>
      <div className={[classes.width95, classes.marginBottom1].join(' ')}>
        <form ref={formRef} onSubmit={handleFormSubmit}>
          <Typography className={classes.marginBottom1} variant="body1">
            {getString('userInfo')}
          </Typography>
          <TextField
            required
            type="email"
            autoComplete="off"
            id="email"
            label={getString('email')}
            value={formInput}
            variant="filled"
            onChange={(e) => setFormInput(e.target.value)}
            fullWidth
            className={classes.marginBottom1}
          />
          <FormControl error={error}>
            <RadioGroup
              name="consentRadio"
              onChange={handleRadioChange}
              className={classes.marginBottom1}
            >
              <FormControlLabel
                classes={{ label: classes.surveyText }}
                value="true"
                control={<Radio required />}
                label={getString('consentPositive')}
              />
              <FormControlLabel
                classes={{ label: classes.surveyText }}
                value="false"
                control={<Radio required />}
                label={getString('consentNegative')}
              />
            </RadioGroup>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {getString('submitButtonLabel')}
            </Button>
          </FormControl>
        </form>
      </div>
    </div>
  )
}

export default ConsentForm
