import React, { useState, useContext } from 'react'
import { Card, CardContent, Button, Grow, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Hearing } from '@material-ui/icons'
import ButtonNext from './ButtonNext'
import { StoreContext } from '../StoreContext'
import styles from '../css-styles'

const useStyles = makeStyles({
  fixed: styles.fixed,
  hearing: styles.hearing,
  displayBlockMarginAuto: styles.displayBlockMarginAuto,
})

const AudioScreen = () => {
  const classes = useStyles()
  const store = useContext(StoreContext)
  const { getString } = store
  const [didPlayCorrect, setDidPlayCorrect] = useState(false)
  const [didPlayIncorrect, setDidPlayIncorrect] = useState(false)

  return (
    <Grow in>
      <Card className={classes.fixed}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {getString('audioScreenTitle')}
          </Typography>
        </CardContent>
        <CardContent>
          <Typography gutterBottom>
            {getString('audioScreenCorrect')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.displayBlockMarginAuto}
          >
            <Hearing
              className={classes.hearing}
              onClick={() => {
                store.playSFX(true)
                setDidPlayCorrect(true)
              }}
            />
          </Button>
          <Typography gutterBottom>
            {getString('audioScreenIncorrect')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.displayBlockMarginAuto}
          >
            <Hearing
              className={classes.hearing}
              onClick={() => {
                store.playSFX(false)
                setDidPlayIncorrect(true)
              }}
            />
          </Button>
          <Typography gutterBottom>{getString('audioScreenBody1')}</Typography>
          <Typography gutterBottom>{getString('audioScreenBody2')}</Typography>
        </CardContent>
        <ButtonNext
          disable={!didPlayCorrect || !didPlayIncorrect}
          onClick={store.incrementPhase}
        >
          {getString('nextButtonLabel')}
        </ButtonNext>
      </Card>
    </Grow>
  )
}

export default AudioScreen
