import React, { useContext } from 'react'

import { Card, CardContent, Grow, Typography } from '@material-ui/core'
import AssignmentFeedback from './AssignmentFeedback'
import { StoreContext } from '../StoreContext'

const AssignmentFeedbackStandalone = () => {
  const { getString } = useContext(StoreContext)

  return (
    <Grow in>
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {getString('assignmentResults')}
          </Typography>
          <AssignmentFeedback />
        </CardContent>
      </Card>
    </Grow>
  )
}

export default AssignmentFeedbackStandalone
