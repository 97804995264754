import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Fab } from '@material-ui/core'
import { StoreContext } from '../../StoreContext'
import Badge from '../Badge'
import styles from '../../css-styles'
import Loading from '../Loading'

const useStyles = makeStyles({
  answerImgDisabled: styles.answerImgDisabled,
  answerImg: styles.answerImg,
})

const FaceButton = ({ answer }) => {
  const [attempted, setAttempted] = useState(false)
  const classes = useStyles()
  const store = useContext(StoreContext)
  const [imageLoaded, setImageLoaded] = useState(true)
  const [sleepCounter, setSleepCounter] = useState(0)
  const [preloadedImage] = useState(new Image())
  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements
  if (preloadedImage.src === '') {
    preloadedImage.src = answer.image
  }

  // Once dummyImage.complete is updated, this will fire and remove loading screen.
  useEffect(() => {
    // Every second increment a counter to check if the image is done loading.
    async function incrementSleep(duration) {
      await new Promise((r) => setTimeout(r, duration))
      setSleepCounter(sleepCounter + 1)
    }
    if (imageLoaded && preloadedImage.complete) {
      return
    }
    if (preloadedImage.complete) {
      setImageLoaded(preloadedImage.complete)
    } else {
      setImageLoaded(false)
      const sleepDuration = sleepCounter >= 10 ? 1000 : 100
      incrementSleep(sleepDuration)
    }
  }, [imageLoaded, setImageLoaded, preloadedImage.complete, sleepCounter])
  if (!imageLoaded) {
    return <Loading />
  }

  return (
    <Fab
      variant="extended"
      style={{ height: 'calc(var(--rvh, 1vh) * 25)', width: '95%' }}
      disabled={attempted}
      onClick={() => {
        setAttempted(true)
        store.setDidAnswerCorrectly(answer.isCorrect)
        store.incrementAttemptNumber()
        if (!store.isPractice || answer.isCorrect) {
          store.nextQuestionWithShortDelay()
        }
      }}
    >
      <span
        className={attempted ? classes.answerImgDisabled : classes.answerImg}
        style={{
          backgroundImage: `url(${answer.image})`,
        }}
      />
      {store.hasVisualFeedback && attempted && (
        <Badge isCorrect={answer.isCorrect} />
      )}
    </Fab>
  )
}

export default FaceButton
