import React, { useContext, useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  // CardActions,
  Grow,
  Typography,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import PracticeChip from '../PracticeChip'
import AnswerButton from '../Signs/AnswerButton'
import { StoreContext } from '../../StoreContext'
import useQuestionStartTime from '../../hooks/useQuestionStartTime'
import styles from '../../css-styles'
import Loading from '../Loading'

const useStyles = makeStyles({
  width95: styles.width95,
  signHero: styles.signHero,
  justifySpaceEvenly: styles.justifySpaceEvenly,
  marginXAuto: styles.marginXAuto,
})

const DigitSignRecall = ({ q, p }) => {
  const classes = useStyles()
  useQuestionStartTime()
  const { getString, isPractice } = useContext(StoreContext)
  const [imageLoaded, setImageLoaded] = useState(true)
  const [sleepCounter, setSleepCounter] = useState(0)
  // Images are preloaded already, test them again to ensure they are loaded for accurate testing measurements
  const [preloadedImage] = useState(new Image())
  if (preloadedImage.src === '') {
    preloadedImage.src = q.url
  }
  // Once dummyImage.complete is updated, this will fire and remove loading screen.
  useEffect(() => {
    // Every second increment a counter to check if the image is done loading.
    async function incrementSleep(duration) {
      await new Promise((r) => setTimeout(r, duration))
      setSleepCounter(sleepCounter + 1)
    }
    if (imageLoaded && preloadedImage.complete) {
      return
    }
    if (preloadedImage.complete) {
      setImageLoaded(preloadedImage.complete)
    } else {
      setImageLoaded(false)
      const sleepDuration = sleepCounter >= 10 ? 1000 : 100
      incrementSleep(sleepDuration)
    }
  }, [imageLoaded, setImageLoaded, preloadedImage.complete, sleepCounter])
  if (!imageLoaded) {
    return <Loading />
  }
  const questionCount = p.questions.length
  const answerButtons = []
  for (let i = 1; i <= questionCount; i += 1) {
    answerButtons.push(
      <Grid item container xs={6} justifyContent="center">
        <AnswerButton
          className={styles.marginXAuto}
          answer={{
            text: i,
            isCorrect: q.target_number === i,
          }}
        />
      </Grid>
    )
  }
  return (
    <Card className={classes.width95}>
      <CardContent>
        {isPractice && <PracticeChip />}
        <Typography>{getString('digitSignRecallPrompt')}</Typography>
      </CardContent>
      <Grow in>
        <CardMedia className={classes.signHero} image={q.url} />
      </Grow>
      <Grid container alignItems="center">
        {answerButtons}
      </Grid>
    </Card>
  )
}

export default DigitSignRecall
