import React from 'react'
import { Card, CardContent, Grow, Typography } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'
import { red } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/styles'
import styles from '../css-styles'

const useStyles = makeStyles({ icon: styles.icon })

const TestInstructions = ({ title, body }) => {
  const classes = useStyles()

  return (
    <Grow in>
      <Card>
        <CardContent>
          <Typography variant="h4">{title}</Typography>
          <ErrorOutline className={classes.icon} htmlColor={red[600]} />
          <Typography variant="body1">{body}</Typography>
        </CardContent>
      </Card>
    </Grow>
  )
}

export default TestInstructions
