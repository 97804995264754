export const preloadImages = (array) => {
  // This is the set of all ImageField names in all of the Django models
  const imageKeys = [
    'image',
    'target_image',
    'foil_image_1',
    'foil_image_2',
    'sorted_image_urls',
  ]

  // The array is the set of questions for a phase in this instance
  array.forEach((element) => {
    // Check each question to see if it has that ImageField, if so, preload it
    imageKeys.forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(element, key)) {
        // if question has sorted_image_urls, then we have an array to preload.
        if (key === 'sorted_image_urls') {
          element[key].forEach((elem) => {
            const preload = new Image()
            preload.src = elem
          })
        } else {
          const preload = new Image()
          preload.src = element[key]
        }
      }
    })
  })
}

export const submit = ({
  id,
  startTime,
  results,
  complete,
  test,
  testId,
  assignmentKey,
  organizationSlug,
}) => {
  const data = {
    assignmentKey,
    participantId: id,
    startTime,
    testVariation: test.name,
    testVersion: test.version,
    testId,
    isComplete: complete,
    results,
    userAgent: navigator.userAgent,
    organizationSlug,
  }
  const url = `/test/${organizationSlug}/api/v1/submit/`
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).catch((err) => console.error(err))
}
