import React from 'react'
import * as Sentry from '@sentry/browser'
import ErrorScreen from './ErrorScreen'
import defaultData from '../defaultData'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, info) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.group('ErrorBoundary')
      console.error(error.message)
      console.log(info)
      console.groupEnd()
    } else {
      Sentry.withScope((scope) => {
        scope.setExtras(info)
        if (error.message !== defaultData.strings.en.invalidLink) {
          Sentry.captureException(error)
        }
        Sentry.captureException(error)
      })
    }
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      // eslint-disable-next-line react/destructuring-assignment
      return <ErrorScreen title="Error" body={this.state.error.message} />
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children
  }
}

export default ErrorBoundary
