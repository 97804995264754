import React, { useContext, useEffect, useState } from 'react'
import Slider from '@material-ui/core/Slider'
import {
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'
import ButtonNext from '../ButtonNext'
import { StoreContext } from '../../StoreContext'

const useStyles = makeStyles({
  root: {
    height: 10,
    borderRadius: 4,
  },
  track: {
    height: 10,
    borderRadius: 4,
  },
  rail: {
    height: 10,
    borderRadius: 4,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
})

const SliderComp = ({
  min,
  max,
  step,
  maxLabel,
  minLabel,
  hasNotApplicable,
  includeNext,
  parentResponseHandler,
  skipNA,
}) => {
  const classes = useStyles()
  const [firstLoad, setFirstLoad] = useState(true)
  const [selectedValue, setSelectedValue] = useState(Math.floor(max / 2))
  const [isDisabled, setIsDisabled] = useState(false)
  const [notApplicableItems, setNotApplicableItems] = useState([])
  const [notApplicableChecked, setNotApplicableChecked] = useState(false)
  const store = useContext(StoreContext)
  const { getString } = store

  const handleChange = (event, value) => {
    setSelectedValue(value)
    // Send the value to the parent element. Used for followup questions
    if (includeNext !== undefined && !includeNext) {
      parentResponseHandler(value)
    }
  }

  const handleNext = () => {
    setIsDisabled(true)
    if (notApplicableChecked) {
      store.setUserResponse('N/A')
      if (skipNA !== null) {
        store.setSkipSurveyQuestions([
          ...store.skipSurveyQuestions,
          ...[skipNA],
        ])
      }
    } else {
      store.setUserResponse(selectedValue)
    }

    store.incrementAttemptNumber()
    store.nextQuestionWithShortDelay()
  }
  const nextButton = []
  // undefined is used in case we forget to set the value
  if (includeNext || includeNext === undefined) {
    nextButton.push(
      <ButtonNext onClick={handleNext} disable={isDisabled}>
        {getString('nextButtonLabel')}
      </ButtonNext>
    )
  }

  useEffect(() => {
    const checkboxChangeHandler = () => {
      if (includeNext !== undefined && !includeNext) {
        if (!notApplicableChecked) {
          parentResponseHandler('N/A')
        } else {
          parentResponseHandler(selectedValue)
        }
      }
      setNotApplicableChecked(!notApplicableChecked)
    }
    if (hasNotApplicable) {
      const order = []
      order.push(
        <FormControlLabel
          control={
            <Checkbox
              onChange={checkboxChangeHandler}
              checked={notApplicableChecked}
            />
          }
          label={store.getString('notApplicable')}
          key={0}
        />
      )
      setNotApplicableItems(order)
    }
  }, [
    hasNotApplicable,
    setNotApplicableItems,
    notApplicableChecked,
    store,
    selectedValue,
    parentResponseHandler,
    includeNext,
  ])

  useEffect(() => {
    // We have a default-selected value, we must send that to the parent caller for followup questions
    if (firstLoad) {
      if (parentResponseHandler) {
        parentResponseHandler(selectedValue)
      }
      setFirstLoad(false)
    }
  }, [firstLoad, parentResponseHandler, selectedValue, setFirstLoad])

  return (
    <>
      <CardContent>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={6}>
            <Typography color="error" align="left">
              {minLabel}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color="error" align="right">
              {maxLabel}
            </Typography>
          </Grid>
        </Grid>
        <Slider
          disabled={notApplicableChecked}
          className={classes.root}
          classes={{
            track: classes.track,
            rail: classes.rail,
            thumb: classes.thumb,
            valueLabel: classes.valueLabel,
          }}
          defaultValue={Math.floor(max / 2)}
          step={step}
          min={min}
          max={max}
          valueLabelDisplay="on"
          marks={[
            { value: min, label: min },
            { value: Math.floor(max / 2), label: Math.floor(max / 2) },
            { value: max, label: max },
          ]}
          onChange={handleChange}
        />
        {notApplicableItems}
      </CardContent>
      {nextButton}
    </>
  )
}
export default SliderComp
